import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { CenterHorizontally } from "./Style";

export function Testimonials() {
  const [cur, setCur] = useState(randomTestimonial());

  useEffect(() => {
    const int = setInterval(() => setCur(randomTestimonial()), 3000);
    return () => clearInterval(int);
  }, []);

  return (
    <CenterHorizontally>
      <TestimonialContainer>{cur}</TestimonialContainer>
    </CenterHorizontally>
  );
}

const TestimonialContainer = styled.q`
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
`;

function randomTestimonial() {
  return testimonials[Math.floor(testimonials.length * Math.random())];
}

const testimonials = [
  "one of the most formative experiences for me as an engineer.",
  "Organic experiences like this don't happen frequently",
  "I can't think of another time I've gotten to meet and learn from such amazing people",
  "It was one of the highlights of my whole year.",
  "Hack Lodge has changed the way I will approach my next few semesters at college and possibly what I choose to do after graduation... building things with the other Hack Lodge participants was an incredible experience.",
  "I feel like I gained twenty new friends",
  "the single most productive week of my life",
  "It was like nothing else I've ever done",
  "I've met more people interested in working on projects at Hack Lodge than over two and a half years at MIT",
  "Hack Lodge is like the best parts of college... a group of motivated friends working on stuff they're excited about for a week",
  "the kind of community that I envisioned that I'd be a part of when I was coming to MIT",
  "As a hackathon organizer, hacklodge was the ideal that other hackathons strive for",
  "HackLodge enabled us to build in one week what would've taken a whole semester",
  "Hack Lodge was one of the most inspiring weeks of my life, hands-down",
];
