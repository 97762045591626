import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./components/App";

if (window.location.pathname !== "/") {
  window.history.replaceState(null, "", "/");
}

const div = document.createElement("div");
document.body.appendChild(div);

ReactDOM.render(<App />, div);
