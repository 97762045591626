import * as _ from "lodash";
import * as React from "react";
import styled, { createGlobalStyle } from "styled-components";

export const darknessRatio = 0.1;
export const darkestRatio = 0.35;

export const background = "white";
export const foreground = "black";

export const MOBILE_WIDTH_CUTOFF = 600;

export const AppWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
`;

export const GlobalStyle = createGlobalStyle`
body {
    background-color: ${background};
    color: ${foreground};
    font-family: 'Roboto Mono', monospace;
    font-size: 10pt;
    text-size-adjust: none;
    overflow-y: scroll !important;
    margin: 0;
}
`;

export const Spacer = (props: { height: number }) => {
  return <div style={{ height: props.height + "px" }} />;
};

export const CenterHorizontally = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const Article = styled.div`
  width: 800px;
  height: auto;
  border: 100px transparent;
  padding: 8px;
  overflow: hidden;

  @media (max-width: ${MOBILE_WIDTH_CUTOFF}px) {
    width: 100vw;
    min-width: 375px;
    box-sizing: border-box;
    padding-right: 10px;
    padding-left: 10px;
  }
`;

export const SectionHeader = styled.div`
  color: black;
  background-color: #e4e4e4;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 32px;
  margin-bottom: 16px;
`;

export const P = styled.div`
  margin: 0;
  padding: 10px 20px;
`;

export const Footer = styled.div`
  width: 100%;
`;

export const Img = styled.img`
  width: 100%;
`;

export const Deemphasize = styled.span`
  font-size: 70%;
  color: rgb(92, 92, 92);
`;

export const HeaderLinks = styled.div`
  float: right;
`;
