import * as React from "react";
import Index from "./Index";
import { GlobalStyle } from "./Style";

export class App extends React.Component {
  render() {
    return (
      <div>
        <GlobalStyle />
        <Index />
      </div>
    );
  }
}
