import * as React from "react";
import styled from "styled-components";
import {
  AppWrapper,
  Article,
  CenterHorizontally,
  Deemphasize,
  Img,
  P,
  Spacer,
  SectionHeader,
  HeaderLinks,
} from "./Style";
import { Testimonials } from "./Testimonials";

const NEO_LINK =
  "https://www.forbes.com/sites/bizcarson/2019/01/18/an-early-facebook-investor-is-creating-a-scouting-network-for-brilliant-engineers/#65618b1e18e1";

const NOTABLE_PROJECTS = [
  {
    description:
      "Fractal sells high-end cloud computers to creative professionals and productivity, workers as a subscription service. Users get unlimited access to a GPU-powered Windows 10 workstation in the cloud streamed to them in real-time at <16.67ms latency to offer near-local experience from any Internet-connected device.",
    link: "https://www.fractalcomputers.com/",
    name: "Fractal Computers",
    authors: [
      "Ming Ying (Harvard '20)",
      "Philippe Noel (Harvard '20)",
      "Nicholas Pipitone (CMU '22)",
    ],
    event: "Hack Lodge Winter '19",
  },
  {
    description:
      "The Open Piano project is a piano and MIDI controller that anyone can afford. It uses the camera on a device that the person already owns, such as a phone or laptop, and is made out of cardboard, so it costs less than five dollars. Because it can communicate over the industry-standard MIDI protocol, it can be used for playing music with a variety of sounds, for creating electronic music, and for learning piano for free online.",
    link: "https://www.theopenpianoproject.com/",
    name: "The Open Piano Project",
    authors: ["Robert Henning (MIT '20)"],
  },
  {
    description:
      "Soup is an experimental programming language with type-dependent, dynamic syntax. Soup is an experiment in adaptive parsing: the syntax of Soup can be changed on the fly, even during the parsing of single file, and in general can be manipulated by library creators as they see fit.",
    link: "https://github.com/agajews/soup-lang",
    name: "Soup",
    authors: ["Alex Gajewski (Columbia '21)"],
  },
  {
    name: "Stealth Drop",
    link: "https://stealthdrop.xyz/",
    description:
      "stealthdrop.xyz is an airdrop utility that enables ERC20 token airdrops that can be claimed by completely anonymous accounts.",
    authors: ["Aayush Gupta", "Adhyyan Sekhsaria", "Nalin Bhardwaj"],
  },
];

const COVER_IMAGE = require("../static/hacklodge_w22.jpg");

const TWO = require("../static/landing-march-2020/2.png");
const THREE = require("../static/landing-march-2020/3.png");
const FIVE = require("../static/landing-march-2020/5.png");
const SIX = require("../static/landing-march-2020/6.png");
const SEVEN = require("../static/landing-march-2020/7.png");
const NEO = require("../static/sponsors/neo-logo.png");
const EF = require("../static/sponsors/ef-logo.png");
const PARC = require("../static/sponsors/0xparc.png");

export default class Index extends React.Component<any, any> {
  render() {
    return (
      <AppWrapper>
        <CenterHorizontally>
          <Article>
            <SectionHeader>
              <strong>Hack Lodge</strong>
              <HeaderLinks>
                <HeaderLink href={"https://directory.hacklodge.org"}>
                  log in
                </HeaderLink>
                <HeaderLink href={"mailto:hello@hacklodge.org"}>
                  email us
                </HeaderLink>
                {/* <HeaderLink href={"https://tinyletter.com/hacklodge"}>
                  sign up
                </HeaderLink> */}
                <HeaderLink href="/#apply">apply by 11/17</HeaderLink>
              </HeaderLinks>
            </SectionHeader>
            <Img src={COVER_IMAGE} />
            <Deemphasize>
              <P>Hack Lodge Winter 2022</P>
            </Deemphasize>
            <Spacer height={16} />

            <P>
              Hack Lodge is an immersive and intimate software development
              program for undergraduates. Teams spend a week working intensely
              on a technical project of their choosing, with the ultimate goal
              of shipping a polished product by the end of the program. Past
              participants include venture-backed founders, researchers,
              international Olympiad winners, and more. We bring in students of
              all experience levels, from students with no formal engineering
              experience to experienced developers with years in industry.
              <a id="apply"></a>
            </P>

            <SectionHeader>Winter 2023</SectionHeader>
            <P>
              <strong>
                Applications for our Winter 2023 event are now open.
              </strong>{" "}
              We will be running a{" "}
              <strong>
                crypto themed Hack Lodge in the Bay Area from 1/8 to 1/15
              </strong>{" "}
              in collaboration with{" "}
              <a href="https://ethuniversity.org/">ETH University</a> focused on
              decentralized technologies.
            </P>

            <P>
              <strong>
                Apply <a href="https://airtable.com/shrx0KwkO9PpswHvR">here</a>{" "}
                by Thursday November 17.
              </strong>{" "}
              Applications are considered on a rolling basis, with earlier
              applications given priority.
            </P>

            <SectionHeader>Testimonials</SectionHeader>
            <P>
              Our W20 participants responded to "How likely would you be to
              recommend Hack Lodge to a friend?" with a median score of 10/10.
            </P>
            <Testimonials />

            <SectionHeader>Recent Projects</SectionHeader>
            <div>
              {NOTABLE_PROJECTS.map((p) => (
                <ProjectDisplay key={p.name} project={p} />
              ))}
            </div>

            <SectionHeader>Sponsors and Mentors</SectionHeader>
            <ul>
              <CenterHorizontally>
                <a href={NEO_LINK}>
                  <img width={150} src={NEO} />
                </a>
                <a href={"https://ethereum.org/en/foundation/"}>
                  <img width={150} src={EF} />
                </a>
                <a href="https://0xparc.org/">
                  <img width={100} src={PARC} />
                </a>
              </CenterHorizontally>
              <li>
                <a href="https://phillipkwang.com/">Phillip Wang</a>
              </li>
              <li>
                <a href="https://twitter.com/k_m_y_l">Kumail Jaffer</a>
              </li>
              <li>
                <a href="https://dcpos.ch/">DC Posch</a>
              </li>
              <li>
                <a href="http://www.michaelseibel.com/">Michael Seibel</a>
              </li>
              <li>
                <a href="https://willrobbins.org/">Will Robbins</a>
              </li>
              <li>
                <a href="https://sparc-camp.org">SPARC</a>
              </li>
              <li>...and several anonymous donors</li>
            </ul>
            <P>
              <strong>
                Want to join this excellent list? Donate to{" "}
                <a href="https://etherscan.io/address/hacklodge.eth">
                  hacklodge.eth
                </a>{" "}
                or <a href="mailto:hello@hacklodge.org">contact us</a>.
              </strong>
            </P>
            <P>
              Hack Lodge is also made possible by program mentors, who spend the
              week teaching and advising students. Past mentors have included:
            </P>
            <ul>
              <li>
                <a href="https://www.youtube.com/watch?v=jNQXAC9IVRw">
                  Jawed Karim
                </a>
              </li>
              <li>
                <a href="https://corylevy.com/">Cory Levy</a>
              </li>
              <li>DC Posch</li>
              <li>
                <a href="https://feross.org/">Feross Aboukhadijeh</a>
              </li>
              <li>
                <a href="http://ivanchub.com/">Ivan Chub</a>
              </li>
              <li>Eugene Chen</li>
            </ul>

            <SectionHeader>Gallery</SectionHeader>

            <Img src={TWO} />
            <Deemphasize>
              <P>Card Games at Hack Lodge W20</P>
            </Deemphasize>

            <Img src={THREE} />
            <Deemphasize>
              <P>Cooking Night!</P>
            </Deemphasize>

            <Img src={FIVE} />
            <Deemphasize>
              <P>Hack Lodge S18</P>
            </Deemphasize>

            <Img src={SIX} />
            <Deemphasize>
              <P>Debugging at 2am</P>
            </Deemphasize>
            <Img src={SEVEN} />
            <Deemphasize>
              <P>Q&A with Ali Partovi</P>
            </Deemphasize>

            <Footer />
            <Spacer height={48} />
          </Article>
        </CenterHorizontally>
      </AppWrapper>
    );
  }
}

interface IProject {
  description: string;
  link: string;
  name: string;
  authors: string[];
}

interface IProjectDisplayProps {
  project: IProject;
}

interface IProjectDisplayState {}

const Footer = styled.div`
  width: 100%;
  padding: 32px 16px;
`;

const ProjectContainer = styled.div`
  display: inline-block;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
`;

const HeaderLink = styled.a`
  padding-left: 16px;
  float: right;
  color: black;
`;

const Author = styled.span`
  font-weight: bold;
`;

class ProjectDisplay extends React.Component<
  IProjectDisplayProps,
  IProjectDisplayState
> {
  render() {
    return (
      <ProjectContainer>
        <a href={this.props.project.link}>{this.props.project.name}</a> <br />
        {this.props.project.authors &&
          this.props.project.authors.map((author) => (
            <Author key={author}>{author + " "}</Author>
          ))}
        <Spacer height={8} />
        <span>{this.props.project.description}</span>
        <Spacer height={32} />
      </ProjectContainer>
    );
  }
}
